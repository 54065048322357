

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Option } from '@/components/base/BaseSelect.vue';
import { AnalyticsTabsTypes } from '@/constants/main_card_inner_tabs';

@Component
export default class AnalyticsCardTypeCheckbox extends Vue {
  @Prop({ type: Object }) readonly typeOption!: Option;

  @Prop({ type: Boolean }) readonly checkboxDisabled!: boolean;

  @Prop({ type: Boolean }) readonly typeIsChecked!: boolean;

  @Prop({ type: String }) readonly selectedTab!: AnalyticsTabsTypes;

  emitToggleChecked(checkedOption: string) {
    this.$emit('checkedChanged', checkedOption);
  }
}
